exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-conditions-js": () => import("./../../../src/pages/conditions.js" /* webpackChunkName: "component---src-pages-conditions-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-gdpr-js": () => import("./../../../src/pages/gdpr.js" /* webpackChunkName: "component---src-pages-gdpr-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-julie-js": () => import("./../../../src/pages/julie.js" /* webpackChunkName: "component---src-pages-julie-js" */),
  "component---src-pages-legal-notice-js": () => import("./../../../src/pages/legal-notice.js" /* webpackChunkName: "component---src-pages-legal-notice-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-paul-js": () => import("./../../../src/pages/paul.js" /* webpackChunkName: "component---src-pages-paul-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-security-policy-js": () => import("./../../../src/pages/security-policy.js" /* webpackChunkName: "component---src-pages-security-policy-js" */),
  "component---src-pages-teddy-js": () => import("./../../../src/pages/teddy.js" /* webpackChunkName: "component---src-pages-teddy-js" */),
  "component---src-pages-violetta-js": () => import("./../../../src/pages/violetta.js" /* webpackChunkName: "component---src-pages-violetta-js" */)
}

